import '../assets/loader.css'

const loadTimeout = setTimeout(() => {
    document.body.classList.add('loaded')
}, 3_000)
document.fonts.onloadingerror = () => {
    console.warn('[documentFontLoadingError]', 'font loading error')
    clearTimeout(loadTimeout)
    document.body.classList.add('loaded')
    document.body.classList.add('error--font-loading')
}
document.fonts.ready.then(function () {
    console.debug('[documentFontsReady]', 'fonts loaded')
    clearTimeout(loadTimeout)
    document.body.classList.add('loaded')
})

setTimeout(() => {
    const splashElement = document.querySelector('body > div.splash--modal')
    if (splashElement) {
        console.debug('[loader]','cleaning up splash element')
        document.body.removeChild(splashElement)
    }
}, 10_000)

export { }
