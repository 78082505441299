import React, { ComponentType } from 'react'
import { FallbackProps } from 'react-error-boundary'
import Button from '@mui/material/Button'

const ErrorHandler: ComponentType<FallbackProps> = ({ error, resetErrorBoundary }) =>
    <div className={'error--handler'}>
        <h2>a problem has occurred...</h2>
        <pre>{error?.message || error}</pre>
        <Button variant={'outlined'} onClick={()=>location.reload()}>Refresh the page</Button>
    </div>

export {
    ErrorHandler,
}
