import en from './en'

const createTranslationMap = <T extends { [name: string]: any }>(translationMap: T) =>
    translationMap

const translations = {
    en: createTranslationMap(en),
}


export default translations
