import React, { Suspense } from 'react'
import { ThemeProvider } from '@emotion/react'

import { I18n } from './shared/react/components/I18n'
import { Services } from './shared/react/components/Services'
import './shared/react/lib/loader'

import { theme } from './assets/theme'
import IndexRouter from './routes'

import './assets/index.css'
import './assets/pwa.css'

import translations from './translations'
import { canInstall, installApplication } from './shared/react/lib/env'

const App = () => {
    return <ThemeProvider theme={theme}>
        <I18n region={'en'} config={translations}>
            <Suspense fallback={null}>
                {canInstall && <div className={'install--prompt'}>
                    <button onClick={() => {
                        installApplication().then((didInstall) => {
                            console.debug('[install]', 'didInstall', didInstall)
                        })
                    }}>install</button>
                </div> || <div id={'installInstructions'}>Install Instructions</div>}
                <Services
                    api={{
                        chat: true
                    }}
                >
                    <IndexRouter />
                </Services>
            </Suspense>
        </I18n>
    </ThemeProvider>
}

export {
    App,
}
