const NUMBER_FORMAT = new Intl.NumberFormat("en")

const translations = {
    title: {
        dashboard: 'Dashboard',
        chat: 'Chat',
        forms: 'Forms',
        users: 'Users',
        share: 'Share',
        video: 'Video',
        login: 'Login',
        logout: 'Logout',
        profile: 'Profile',
        missing: 'Page not found',
        error: 'Error loading page',
    },
    error: {
        pageMissing: 'Page not found :(',
        unknownIssue: 'Unknown issue',
    },
    label: {
        version: 'Version',
        applicationMode: 'Mode',
    },
    intro: {
        welcome: 'Welcome, {{username}}!',
        text: 'I hope you find this useful.',
        share: 'A link was shared with this application.',
    },
    cookies: {
        consentTitle: 'We value your privacy',
        consentBody: 'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept Cookies", you consent to our use of cookies. ',
        consentAcceptButton: 'Accept Cookies',
        consentDeclineButton: 'Decline Cookies',
    },
    chat: {
        joinStream: 'Join Stream',
        leaveStream: 'Leave Stream',
        unreadMessages: (count: number) =>
            `${NUMBER_FORMAT.format(count)} unread message${count>1?'s':''}`
    },
}

export default translations
