import { getNamespaceFromUri } from "../../types/namespace"

enum ApplicationMode {
    Web = 'web',
    PWA = 'pwa',
}
//@ts-ignore
const isWKWebView = (navigator?.platform?.substr(0,2) === 'iP' && window?.webkit && window?.webkit?.messageHandlers)

const getApplicationMode = (): ApplicationMode => {
    if (isWKWebView || document.cookie.indexOf('pwa_mode=') !== -1) {
        return ApplicationMode.PWA
    }
    const tempMode = String(new URLSearchParams(document.location.search).get('mode') || 'web').replace(/[^a-z0-9\-_]+/, '') as ApplicationMode
    if (Object.values(ApplicationMode).includes(tempMode)) {
        return tempMode
    }
    console.error('[env]', 'incorrect application mode', tempMode)
    return ApplicationMode.Web
}

const applicationMode = getApplicationMode()
console.debug('[env]', 'mode', applicationMode)

window.__CLIENT_TYPE__ = 'web-client'
window.__PORPOISE_VERSION__ = __PORPOISE_VERSION_RAW__
window.__APPLICATION_MODE__ = applicationMode
window.__PORPOISE_NAMESPACE__ = getNamespaceFromUri()//'https://dorian.live/foo')

const __CLIENT_TYPE__ = window.__CLIENT_TYPE__
const __PORPOISE_VERSION__ = window.__PORPOISE_VERSION__
const __APPLICATION_MODE__ = window.__APPLICATION_MODE__
const __PORPOISE_NAMESPACE__ = window.__PORPOISE_NAMESPACE__

document.body.classList.add(`mode--${applicationMode}`)

let deferredPrompt
let installApplication = async () => {
    console.debug('[installApplication]')
    // deferredPrompt is a global variable we've been using in the sample to capture the `beforeinstallevent`
    deferredPrompt.prompt()
    // Find out whether the user confirmed the installation or not
    const { outcome } = await deferredPrompt.userChoice
    // The deferredPrompt can only be used once.
    deferredPrompt = null
    // Act on the user's choice
    if (outcome === 'accepted') {
        console.debug('[installApplication]', 'user accepted the install prompt')
        return true
    } else if (outcome === 'dismissed') {
        console.warn('[installApplication]', 'user dismissed the install prompt')
        return false
    }
}

let canInstall = false
window.addEventListener('beforeinstallprompt', (e) => {
    console.debug('[beforeinstallprompt]')
    e.preventDefault()
    //@ts-ignore
    installApplication = e
    canInstall = true
})

const registerServiceWorker = async () => {
    if ("serviceWorker" in navigator) {
        try {
            const registration = await navigator.serviceWorker.register("/sw.js", {
                scope: "/",
            })
            if (registration.installing) {
                console.debug('[sw]', 'installing')
            } else if (registration.waiting) {
                console.debug('[sw]', 'installed')
            } else if (registration.active) {
                console.debug('[sw]', 'active')
            }
        } catch (error) {
            console.error('[registerServiceWorker]', `registration failed with ${error}`)
        }
    }
}

export {
    __CLIENT_TYPE__,
    __PORPOISE_VERSION__,
    __APPLICATION_MODE__,
    __PORPOISE_NAMESPACE__,

    registerServiceWorker,
}

export {
    canInstall,
    installApplication,
}
