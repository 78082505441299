import React from 'react'
import Avatar from '@mui/material/Avatar'
import { UserSessionContext } from '../lib/useUser'
import { deepOrange, deepPurple } from '@mui/material/colors'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import PersonIcon from '@mui/icons-material/Person'
import { LIVE_ROOT_DOMAIN, ROOT_DOMAIN, Namespace } from '../../types/namespace'

const USER_API_HOST = __PORPOISE_NAMESPACE__ === Namespace.Production
    ? `user.${LIVE_ROOT_DOMAIN}`
    : `user.${__PORPOISE_NAMESPACE__}.${ROOT_DOMAIN}`

interface ProfileAvatarProps {
    user?: UserSessionContext
    onClick?: () => void
    size?: 'tiny' | 'medium' | 'large'
}

const profileSizes = {
    'tiny': '32px',
    'medium': '64px',
    'large': '200px',
}

const ProfileAvatar = ({ size = 'medium', user, onClick }: ProfileAvatarProps) => {
    const psize = profileSizes[size]

    if (!user || !user?.data.authenticated) {
        return <Avatar
            onClick={onClick}
            alt={'Not Authenticated'}
            sx={{
                width: psize,
                height: psize,
                bgcolor: deepOrange[500],
            }}
        >
            <PersonOffIcon />
        </Avatar>
    }
    if (user?.data?.profile?.hash) {
        return <Avatar
            onClick={onClick}
            alt={user?.data.profile?.nickname}
            sx={{
                width: psize,
                height: psize,
            }}
            src={`https://${USER_API_HOST}/public/${user?.data?.profile?.hash}/image`}
        />
    }
    return <Avatar
        alt={user?.data.profile?.nickname?.[0] || '?'}
        onClick={onClick}
        sx={{
            width: psize,
            height: psize,
            bgcolor: deepPurple[500],
        }}
    >
        <PersonIcon />
    </Avatar>
}

export {
    ProfileAvatar,
}
