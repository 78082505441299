import React, { useEffect, useState } from 'react'

import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'

import GoogleIcon from '@mui/icons-material/Google'
import FacebookIcon from '@mui/icons-material/Facebook'
import AppleIcon from '@mui/icons-material/Apple'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1

import { useServices } from '../components/Services'
import { AutoFormWrapper } from '../components/AutoForm'
import type { DataSchema } from '../../types/schema'
import { IdentityProvider } from '../../types/user'

import '../assets/securecontainer.css'
import { UserSessionContext } from '../lib/useUser'

interface LoginProviderListProps {
  user?: UserSessionContext
}

const LoginProviderList = ({ user }: LoginProviderListProps) =>
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  }}>
    <Button
      endIcon={<GoogleIcon />}
      onClick={() => {
        user?.login('Google' as IdentityProvider)
      }}
      variant={'contained'}
      sx={{
        backgroundColor: '#4285F4',
        justifyContent: 'left',
      }}
    >Sign in with Google</Button>
    <Button
      endIcon={<AppleIcon />}
      onClick={() => {
        user?.login('SignInWithApple' as IdentityProvider)
      }}
      variant={'contained'}
      sx={{
        backgroundColor: '#0d0d0d',
        justifyContent: 'left',
      }}
    >Sign in with Apple</Button>
    <Button
      endIcon={<FacebookIcon />}
      sx={{
        backgroundColor: '#3b5998',
        justifyContent: 'left',
      }}
      onClick={() => {
        user?.login('Facebook' as IdentityProvider)
      }}
      variant={'contained'}
    >Sign in with Facebook</Button>
  </div>

interface FormLoginProps {
  onComplete: (formData: any, result: any) => void
  onError?: (error: any) => Promise<void>
  onCancel: () => void
  schema: DataSchema
  user?: UserSessionContext
}

const FormLogin = ({ user, onComplete, onCancel, schema }: FormLoginProps) =>
  <AutoFormWrapper
    id={'user_login'}
    schema={schema}
    action={user?.api.authenticate.bind(user?.api) || (() => { throw new Error('missing action') })}
    onSuccess={async (formData, result) => {
      console.log('FORMDATA', formData)
      console.log('RESULT', result)
      onComplete(formData, result)
    }}
    onCancel={onCancel}
  />

const LoginModal = ({
  modal,
  setModal,
  schema,
  onFailure,
  onSuccess,
}: any) => {
  const { user } = useServices()
  const [loaded, setLoaded] = useState(modal)
  useEffect(() => {
    if (modal) {
      setLoaded(true)
    }
  }, [modal])

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return <Dialog
    open={modal}
    fullScreen={isMobile || fullScreen}
    onClose={() =>
      onFailure()}
    className={'login--modal'}
    sx={{
      "& .MuiDialogContent-root form": {
        width: 340,
      },
    }}
  >
    <DialogContent sx={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        {loaded && <FormLogin
          user={user}
          schema={schema}
          onCancel={() =>
            onFailure('CANCEL')}
          onError={async (e) => {
            console.error(e)
            onFailure('ERROR')
          }}
          onComplete={(profile: any) =>
            onSuccess(profile)}
        />}
        <Divider sx={{
          margin: '1.5em 0',
          textTransform: 'uppercase',
          color: '#a5a5a5',
          fontSize: '0.9em',
          pointerEvents: 'none',
        }}>or</Divider>
        <LoginProviderList
          user={user} />
      </div>
    </DialogContent>
  </Dialog>
}

export {
  LoginModal,
}
