import React, { useState } from 'react'
import { format, fromUnixTime } from 'date-fns'
import { useServices } from '../shared/react/components/Services'
import '../assets/profile.css'
import clsx from 'clsx'
import { ProfileAvatar } from '../shared/react/components/Profile'


const PROFILE_DATE_FORMAT = 'E io LLL y @ HH:mm'

const Profile = () => {
    const { user } = useServices()
    const { profile, authenticated } = user?.data || { profile: undefined, authenticated: false }
    const [visible, setVisible] = useState(false)

    return !authenticated
        ? <div key={'profile--no-auth'} className={'fade-in'}>
            <ProfileAvatar
                size={'tiny'}
            />
        </div>
        : <div key={'profile--auth'} className={clsx('fade-in', { visible })}>
            {profile?.picture?.length > 0 &&
                <div key={'profile--auth-image'} className={'center'}>
                    <ProfileAvatar
                        size={'tiny'}
                        onClick={() =>
                            setVisible(_visible =>
                                !_visible)}
                        user={user}
                    />
                </div>}
            <div key={'profile--info'} style={{
                display: visible ? 'block' : 'none'
            }}>
                <div
                    key={'profile-wrapper'}
                    className={'profile--wrapper'}
                >
                    <div key={'profile-wrapper-div'}>
                        <div key={'profile-username'}>
                            <div key={'item-head'} className={'profile--info--label'}>Username</div>
                            <div key={'item-body'}>{profile?.username || '-'}</div>
                        </div>
                        <div key={'profile-email'}>
                            <div key={'item-head'} className={'profile--info--label'}>Email</div>
                            {profile?.email && <div key={'item-body'}><a href={`mailto:${profile.email}`}>{profile.email}</a></div> || <div>-</div>}
                        </div>
                        <div key={'profile-nickname'}>
                            <div key={'item-head'} className={'profile--info--label'}>Nickname</div>
                            <div key={'item-body'}>{profile?.nickname || '-'}</div>
                        </div>
                        <div key={'profile-verified'}>
                            <div key={'item-head'} className={'profile--info--label'}>Verified</div>
                            <div key={'item-body'}>{(profile?.email_verified && (profile.email_verified ? 'true' : 'false')) || '-'}</div>
                        </div>
                        <div key={'profile-hash'}>
                            <div key={'item-head'} className={'profile--info--label'}>Hash</div>
                            <div key={'item-body'} style={{ fontFamily: 'monospace' }}>{profile?.hash || '-'}</div>
                        </div>
                        <div key={'profile-login-provider'}>
                            <div key={'item-head'} className={'profile--info--label'}>Login Provider</div>
                            <div key={'item-body'}>{profile?.login_provider || '-'}</div>
                        </div>
                        <div key={'profile-signup-timestamp'}>
                            <div key={'item-head'} className={'profile--info--label'}>Signup Timestamp</div>
                            <div key={'item-body'}>{(profile?.signup_date
                                //@ts-ignore
                                && format(new Date(profile.signup_date), PROFILE_DATE_FORMAT)) || '-'}</div>
                        </div>
                        <div key={'profile-groups'}>
                            <div key={'item-head'} className={'profile--info--label'}>Groups</div>
                            <div key={'item-body'}>
                                {profile?.groups?.map((group) =>
                                    <div className={'chip'}>{group}</div>)}
                            </div>
                        </div>
                        <div key={'profile-timestamp'}>
                            <div key={'item-head'} className={'profile--info--label'}>Login Timestamp</div>
                            <div key={'item-body'}>{(profile?.auth_time
                                //@ts-ignore
                                && format(fromUnixTime(profile.auth_time), PROFILE_DATE_FORMAT)) || '-'}</div>
                        </div>
                        <div key={'profile-login-expires'}>
                            <div key={'item-head'} className={'profile--info--label'}>Login Expires</div>
                            <div key={'item-body'}>{(profile?.exp
                                //@ts-ignore
                                && format(fromUnixTime(profile.exp), PROFILE_DATE_FORMAT)) || '-'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default Profile
