import type { } from '@mui/x-data-grid/themeAugmentation'

import { ThemeOptions, createTheme } from '@mui/material/styles'

import './mui.css'

const isDarkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
console.debug('[theme]', 'isDarkMode', isDarkMode)

const themeOptions: ThemeOptions = {
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    // backgroundColor: 'red',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    marginBottom: '0.5em',
                },
            }
        },
    },
    typography: {
        fontFamily: 'sans-serif',
    },
    palette: {
        mode: isDarkMode ? 'dark' : 'light',

        primary: {
            main: isDarkMode ? '#ba32a8' : '#3c1a5a',
            // contrastText: '#fff',
        },
        secondary: {
            main: '#f50057',
        },
    },
}

if (themeOptions.palette?.mode == 'dark') {
    document.body.classList.add('dark')
}

const theme = createTheme(themeOptions)

export {
    theme,
}
