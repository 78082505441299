import {
    __PORPOISE_VERSION__,
    __CLIENT_TYPE__,
    __APPLICATION_MODE__,
} from './shared/react/lib/env'
import React from 'react'
import { render } from 'react-dom'
import { App } from './App'

console.debug('[root]', 'version', __PORPOISE_VERSION__, __CLIENT_TYPE__)
render(<App />, document.getElementById('root'))
