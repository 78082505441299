import clsx from 'clsx'
import React from 'react'
import { RouteObject, To, UNSAFE_NavigationContext, matchRoutes, useLocation, useNavigate, useResolvedPath } from "react-router-dom"

const findComponentForRoute = (path, routes, _prefix: string[] = []) => {
    const matchedRouteList = matchRoutes(routes, path)?.filter(({ route }) => !route.index)
    const matchedRoute = matchedRouteList?.pop()

    //@ts-expect-error
    return matchedRoute?.route?.element?.type || null
}

const routePreloader = (path: To, routes: RouteObject[]) => {
    const component = findComponentForRoute(path, routes)

    if (component && component.preload) {
        component.preload()
    }
}

const createAutoLinker = (routes: RouteObject[]) =>
    ({ to, children, ...rest }) => {
        const navigate = useNavigate()
        let path = useResolvedPath(to, {
            relative: undefined
        })
        let location = useLocation();
        //   let routerState = React.useContext(UNSAFE_DataRouterStateContext);
        let {
            navigator
        } = React.useContext(UNSAFE_NavigationContext);
        let toPathname = navigator.encodeLocation ? navigator.encodeLocation(path).pathname : path.pathname;
        let locationPathname = location.pathname;
        //   let nextLocationPathname = routerState && routerState.navigation && routerState.navigation.location ? routerState.navigation.location.pathname : null;
        const end = false
        let isActive = locationPathname === toPathname || !end && locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === "/";

        return <a
            onMouseEnter={() =>
                routePreloader(to, routes)}
            className={clsx('auto--link', { active: isActive })}
            onClick={(evt) => {
                evt.preventDefault()
                console.debug('[LinkWithPreload]', 'click')
                //@ts-ignore
                if (!document.startViewTransition) {
                    navigate(to)
                    return
                } else {
                    console.debug('[LinkWithPreload]', 'has view transitions')
                    //@ts-ignore
                    document.startViewTransition(() => {
                        navigate(to)
                    })
                    return
                }
            }}
        >{children}</a>
    }

export {
    createAutoLinker,
}
